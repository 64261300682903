body {
  margin: 0;
  font-family: 'Roboto';

  .form {
    height: 100%;
    width: 100%;
  }

  /*******MUI CUSTOMIZATION********/
  .MuiFormLabel-colorError {
    color: #d32f2f;
  }
  .MuiListItemButton-root.Mui-selected {
    font-weight: bold;
  }
}

.leads-board {
  background-color: #E7E7E7 !important;
}

.option-select-simples:hover {
  background-color: #c2c2c2;
}

.pointer {
  cursor: pointer !important;
}

.smooth-dnd-container {
  min-height: 800px;
}

div::-webkit-scrollbar {
  width: 4px; 
  height: 4px; 
}

div::-webkit-scrollbar-thumb {
  background: #b6b6b6;
  border-radius: 4px;
}

div::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
}
